import React, { useCallback } from "react";

import styles from "./styles/RecordContainer.module.scss";
import ContentContainer from "../../components/ContentContainer";

import { Select } from 'antd';
import TableLayout from "../../components/TableLayout";
import { useReservationHeader } from "../../schemas/tableHeaderSchema";


const RecordContainer = ({ status, setStatus, setMainPage, setDetails, data }) => {
    const header = useReservationHeader({ status, setDetails, setMainPage });
    const options = [
        { value: 'pending', label: 'Pending' },
        { value: 'in-process', label: 'In Process' },
        { value: 'completed', label: 'Completed' },
        { value: 'cancelled', label: 'Cancelled' }]



    const handleStatusChange = useCallback((value) => {
        setStatus(value)
    }, [])

    return (
        <ContentContainer>
            <div className={styles.recordContainer}>
                <Select
                    value={status}
                    onChange={handleStatusChange}
                    options={options}
                    className={styles.select}
                    size="large"
                />
                <TableLayout headers={header} data={data} status={'success'} />
            </div>
        </ContentContainer>

    )
}

export default React.memo(RecordContainer)