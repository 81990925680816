import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';
import 'antd/dist/antd.min.css';

import ProtectedLayout from "./components/ProtectedLayout";
import ContentLayout from "./components/ContentLayout";

import PublicRoute from "./components/Routes/PublicRoute";
import ProtectedRoute from "./components/Routes/ProtectedRoute";

import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Spot from "./pages/Spot";

import { useLocalStorage } from "./hooks/useLocalStorage";
import Profile from "./pages/Profile";
import Reservation from "./pages/Reservation";
import ChangePassword from "./pages/ChangePassword";
import Chat from "./pages/Chat";


function App() {
  const user = useLocalStorage();

  return (
    <Router>
      <Routes>
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/login`} element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='0' isSearchBar={true}>
              <ContentLayout>
                <Dashboard />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/venue`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='1' isSearchBar={true}>
              <ContentLayout>
                <Spot />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/profile`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='2' isSearchBar={true}>
              <ContentLayout>
                <Profile />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/reservation`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='3' isSearchBar={true}>
              <ContentLayout>
                <Reservation />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/change-password`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='4' isSearchBar={true}>
              <ContentLayout>
                <ChangePassword />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/chat`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='5' isSearchBar={true}>
              <ContentLayout>
                <Chat />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route path={`${process.env.REACT_APP_DOMAIN_DIR}/*`} element={<Navigate to={`${process.env.REACT_APP_DOMAIN_DIR}/login`} />} />
      </Routes >


    </Router >
  );
}

export default App;
