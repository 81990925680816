import React, { useState } from "react";
import ContentContainer from "../../components/ContentContainer";
import TableLayout from "../../components/TableLayout";
import { spotInfoHeader } from "../../schemas/tableHeaderSchema";
import { Dashboard } from "../../Api/Dashboard";
import { Toast } from "../../hooks/useToast";

import { useQuery } from "react-query";
import { useSelector } from "react-redux";

export default function SpotListing({ handleBackClick }) {

    const { api_token } = useSelector(state => state.profile.data)
    const { data, isLoading } = useQuery({
        queryKey: ['dashboard', 'spot'],
        queryFn: () => Dashboard.getSpotListing(api_token),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
        onError: (err) => Toast(err.message, 'error', false)
    })
    return (
        <ContentContainer hasOptions={true} hasBackButton={true} handleBackButtonClick={handleBackClick}>
            <TableLayout headers={spotInfoHeader} data={data?.data ?? []} status={!isLoading ? 'success' : 'pending'} />
        </ContentContainer>
    )

}