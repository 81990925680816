import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';



const initialState = {
    search: "",
}

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setValue: (state, actions) => {
            return {
                search: actions.payload
            }
        },
        resetValue: (state, actions) => {
            return {
                search: ""
            }
        }
    },
})


// Action creators are generated for each case reducer function
export const { setValue, resetValue } = searchSlice.actions

export default searchSlice.reducer