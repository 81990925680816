import React, { useState } from "react";

import styles from "./index.module.scss";

import { Toast } from "../../hooks/useToast";
import { Profile } from "../../Api/Profile";
import ContentContainer from "../../components/ContentContainer";


import { Input } from "antd";
import { useDispatch } from "react-redux";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdOutlineVisibility } from "react-icons/md"
import { updateProfilePasswordThunk } from "../../features/ProfileReducer";
import { updateKey } from "../../features/DefaultReducer";

export default function ChangePassword() {


    const dispatch = useDispatch()
    const [data, setData] = useState({
        current_password: "",
        new_password: "",
        confirm_password: ""
    });



    function handleChange(e) {
        const { name, value } = e.target;
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    async function handleSubmit() {

        const format = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,150}$/;

        const { current_password, new_password, confirm_password } = data;
        if (!current_password || !new_password || !confirm_password) return Toast("Field Should Not Be Empty", 'error', false);
        if (!new_password.match(format)) return Toast('Password should be atleast 8 characters long and contain number, capital letter and special character', 'error', false);
        if (new_password !== confirm_password) return Toast("Confirm Password Is Not Same As New Password", 'error', false);

        dispatch(updateProfilePasswordThunk({
            data,
            handleClose: () => {
                localStorage.removeItem(process.env.REACT_APP_WEB_STORAGE_TOKEN);
                dispatch(updateKey({ isLoggedIn: false }))
            }
        }))


    }
    return (
        <ContentContainer>
            <div className={styles.changePassword}>
                <h1 className={styles.contentTitle}>Change Password</h1>
                <div className={styles.content}>
                    <div className={styles.row}>
                        <Input.Password
                            placeholder="Password"
                            autoComplete="off"
                            name="current_password"
                            value={data.current_password}
                            onChange={handleChange}
                            iconRender={(visible) => (visible ? <MdOutlineVisibility /> : <AiOutlineEyeInvisible />)}
                        />
                    </div>
                    <div className={styles.row}>
                        <Input.Password
                            placeholder="New Password"
                            autoComplete="off"
                            name="new_password"
                            value={data.new_password}
                            onChange={handleChange}
                        />

                    </div>
                    <div className={styles.row}>
                        <Input.Password
                            placeholder="Confirm Password"
                            autoComplete="off"
                            name="confirm_password"
                            value={data.confirm_password}
                            onChange={handleChange}
                        />

                    </div>
                    <button className={styles.submit} onClick={handleSubmit}>
                        Update
                    </button>

                </div>

            </div>
        </ContentContainer>
    )
}