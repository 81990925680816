import { Axios } from "../hooks/useAxiosInstance";

export const UploadMedia = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        post: async (data) => await postRecord(data),
    })

    async function postRecord(data) {
        const url = "upload";
        const option = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        return await Axios.post(url, data, option);
    }

    return apis();

})()
