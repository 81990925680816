import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function ProtectedRoute({ children }) {

    const { isLoggedIn } = useSelector(state => state.default);


    return (
        isLoggedIn ? children : <Navigate to={`${process.env.REACT_APP_DOMAIN_DIR}/login`} replace />

    );
}

export default ProtectedRoute;