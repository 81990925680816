import React, { useState } from "react";
import ModalContainer from "../ModalContainer.js";

import styles from "./index.module.scss"
import { Input } from "antd";
import { RxCross2 } from "react-icons/rx"
import { updateProfilePasswordThunk } from "../../features/ProfileReducer.js";
import { useDispatch } from "react-redux";


export default function PasswordPopUp({ handleClose }) {
    const dispatch = useDispatch();
    const [data, setData] = useState({
        current_password: '',
        new_password: '',
        confirm_password: ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleSubmit = () => {
        dispatch(updateProfilePasswordThunk({ data, handleClose }))
    }
    return (
        <ModalContainer>
            <div className={styles.passwordPopUp}>
                <div className={styles.headerContainer}>
                    <h1 className={styles.title}>Change Password</h1>
                    <span onClick={handleClose}><RxCross2 /></span>
                </div>
                <hr className={styles.divider} />
                <div className={styles.fieldsContainer}>
                    <Input.Password
                        placeholder="Enter Current Password"
                        className={styles.field}
                        name='current_password'
                        value={data.current_password}
                        onChange={handleChange}
                    />
                    <Input.Password
                        placeholder="Enter New Password"
                        className={styles.field}
                        name='new_password'
                        value={data.new_password}
                        onChange={handleChange}
                    />
                    <Input.Password
                        placeholder="Enter Confirm Password"
                        className={styles.field}
                        name='confirm_password'
                        value={data.confirm_password}
                        onChange={handleChange}
                    />
                    <button className={styles.submit} onClick={handleSubmit}>
                        Change Password
                    </button>
                </div>

            </div>
        </ModalContainer>

    )
}