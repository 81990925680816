import React, { useCallback, useEffect, useMemo, useState } from "react";

import styles from "./styles/SpotDetails.module.scss"
import ContentContainer from "../../components/ContentContainer";
import MapContainer from "../../components/Map/MapContainer";
import ModalContainer from "../../components/ModalContainer.js";
import { Toast } from "../../hooks/useToast";
import AddOrUpdateSpot from "./AddOrUpdateSpot";
import { Spot } from "../../Api/Spot";

import { Carousel, Col, DatePicker, Rate, Row } from "antd";
import { TbMapPinSearch } from "react-icons/tb"
import { FaMapMarkerAlt } from "react-icons/fa"
import { BsBookmarkStarFill } from "react-icons/bs"
import { CgWebsite } from "react-icons/cg"
import { RxCross2 } from "react-icons/rx"
import { useSelector } from "react-redux";
import { useMutation, useQueries, useQueryClient } from "react-query";
import { confirmAlert } from "react-confirm-alert";
import { useInView } from "react-intersection-observer";
import { useInfiniteQuery } from "react-query";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Bar } from "react-chartjs-2";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Tooltip } from "chart.js";
import moment from "moment";

ChartJS.register(BarElement, CategoryScale, Legend, LinearScale, Tooltip);

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const SpotDetails = ({ data, setData, backClick }) => {
    const { ref, inView } = useInView();
    const { api_token } = useSelector(state => state.profile.data);
    const queryClient = useQueryClient()

    const [isMap, setIsMap] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [reviewModal, setReviewModal] = useState(false);
    const [endDate, setEndDate] = useState(moment());
    const [checkInEndDate, setCheckInEndDate] = useState(moment());

    const [enabled, setEnabled] = useState(true);
    const { data: reviewsData, isFetched, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
        useInfiniteQuery(['spot', data.slug, 'reviews'], async ({ pageParam = 1 }) => await Spot.getReviews(data.slug, pageParam, api_token), {
            enabled: enabled,
            refetchOnMount: true,
            refetchOnReconnect: true,
            refetchOnWindowFocus: false,
            retry: 0,
            onSuccess: () => {
                setEnabled(false)
            },
            onError: (err) => Toast(err.message, 'error', false),
            getNextPageParam: (lastPage, allPages) => {
                return (lastPage.links.current < lastPage.links.total) ? lastPage.links.current + 1 : false
            },
        });


    const [reviewsWeely, checkInWeekly] = useQueries([
        {
            queryKey: ['spot', data.slug, 'review', 'weekly', endDate.week(), endDate.year()],
            queryFn: () => Spot.getReviewsWeekly(endDate.format('YYYY-MM-DD'), data.slug, api_token),
            refetchOnMount: true,
            refetchOnReconnect: true,
            refetchOnWindowFocus: false,
        },
        {
            queryKey: ['spot', data.slug, 'check-in', 'weekly', checkInEndDate.week(), checkInEndDate.year()],
            queryFn: () => Spot.getCheckInWeekly(checkInEndDate.format('YYYY-MM-DD'), data.slug, api_token),
            refetchOnMount: true,
            refetchOnReconnect: true,
            refetchOnWindowFocus: false,
        },
    ]);


    const deleteSpotMutation = useMutation({
        mutationFn: async () => await Spot.delete(data.slug, api_token),
        onSuccess: () => {
            Toast('Spot Deleted Successfully', 'success', false);
            queryClient.setQueryData(["spots"], (res) => {
                return {
                    ...res,
                    pages: res.pages.map(item => {
                        return {
                            ...item,
                            data: item.data.filter(item => item.slug !== data.slug)
                        }
                    })
                }
            })
            backClick();
        },
        onError: (err) => {
            Toast(err.message, 'error', false)
            setIsSubmitted(false);
        }
    })


    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage]);


    const setClose = useCallback(() => {
        setIsMap(false)
    }, [])


    const handleReviewsClose = useCallback(() => {
        setReviewModal(false)
    }, [])

    const handleDeleteButtonClick = useCallback(() => {
        if (isSubmitted) return;
        confirmAlert({
            title: "Confirm Deletion",
            message: "Are you sure you want to delete?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setIsSubmitted(true);
                        Toast('Deleting Spot', 'loading', true);
                        deleteSpotMutation.mutate();
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
        });

    }, [])

    const handleEditButtonClick = useCallback(() => {
        setIsEdit(true)
    }, [])

    const closeEditModal = useCallback(() => {
        setIsEdit(false)
    }, [])


    return (
        <ContentContainer hasOptions={true} handleBackButtonClick={backClick} hasBackButton={true} hasDeleteButton={true} hasEditButton={true} handleDeleteButtonClick={handleDeleteButtonClick} handleEditButtonClick={handleEditButtonClick}>
            {isMap ? <MapContainer setLatLng={() => { }} setClose={setClose} location={{ lat: data.latitude, lng: data.longitude }} /> : ""}
            {isEdit ? <AddOrUpdateSpot isEdit={true} handleClose={closeEditModal} details={data} setDetails={setData} handleEnableQuery={() => { }} /> : ''}
            {reviewModal ? <ModalContainer handleClose={handleReviewsClose}>
                <div className={styles.reviewsModal}>
                    <div className={styles.headerContainer}>
                        <h1 className={styles.title}>Reviews</h1>
                        <span onClick={handleReviewsClose}><RxCross2 /></span>
                    </div>
                    <hr style={{ margin: '10px 0 20px 0' }} />
                    <div className={styles.modalReviewsContainer}>
                        {isFetched && !isLoading && !((reviewsData?.pages ?? []).length ? reviewsData.pages[0].data.length ? true : false : false) ? <div className='emptyContainer'>
                            <h2 className='title'>No Reviews Found</h2>
                        </div> :
                            (reviewsData?.pages ?? []).map((page, i) => {
                                const halfPage = Math.floor(reviewsData.pages.length / 2);
                                return (page?.data ?? []).map((item, index) => {
                                    return <div className={styles.review} key={index} ref={(i == halfPage && index === Math.floor((page?.data.length - 1) / 2) && hasNextPage) ? ref : undefined}>
                                        <div className={styles.headerContainer}>
                                            <img src={item.user_image_url} alt="User Image" className={styles.user_avatar} />
                                            <div className={styles.info}>
                                                <div className={styles.header}>
                                                    <h3 className={styles.name}>{item.user_name}</h3>
                                                    <p className={styles.date}>{item.review_date}</p>
                                                </div>
                                                <div className={styles.rating}><Rate allowHalf defaultValue={item.rating} disabled style={{ fontSize: "15px" }} /></div>

                                            </div>
                                        </div>
                                        <p className={styles.description}>{item.review}</p>

                                    </div>
                                })
                            })
                        }
                        {(isLoading || isFetchingNextPage) ? <div className={'loadingContainer'}>
                            <Spin indicator={antIcon} style={{ fontSize: "24px" }} />
                        </div> : ''}
                    </div>
                </div>
            </ModalContainer> : ''}
            <div className={styles.spotDetails}>
                <div className={`${styles.containers} ${styles.detailsContainer}`}>
                    <div className={styles.row}>
                        <div className={styles.imageContainer}>
                            <Carousel effect="fade" autoplay>
                                {[data.banner_image, ...data.image_url].map((src, index) => <img src={src} alt="restaurant image" className={styles.bannerImage} key={`Restaurant Images ${index}`} />)}
                            </Carousel>

                        </div>
                        <div className={styles.infoContainer}>
                            <div className={styles.titleContainer}>
                                <h1 className={styles.title}>{data.name}</h1>
                                <p className={styles.checkIns}>
                                    <TbMapPinSearch /> {data.total_checkIns ?? 0} Check in
                                </p>
                            </div>
                            {/* <div className={styles.ratingContainer}>
                                <div className={styles.rating}><Rate allowHalf defaultValue={data.total_rating ?? 0} disabled style={{ fontSize: "15px" }} /> ({data.total_reviews ?? 0} Reviews)</div>
                            </div> */}
                            <div className={styles.tagsContainer}>
                                {data.tags.map((item, index) => {
                                    return <p className={styles.tags} key={index}>
                                        <span>-</span>{item}

                                    </p>
                                })}


                            </div>
                            <p className={styles.map} onClick={() => setIsMap(true)}>
                                <FaMapMarkerAlt style={{ color: "red" }} /> {data.address}
                            </p>
                            {data.website_url ? <a className={styles.website} href={data.website_url} target="_">
                                <CgWebsite /> {data.website_url}
                            </a> : ''}
                            {data.reservation_url ? <a className={styles.website} href={data.reservation_url} target="_">
                                <CgWebsite /> Reservation Page
                            </a> : ''}
                            {/* <p className={styles.viewReviews} onClick={() => setReviewModal(true)}>
                                <BsBookmarkStarFill style={{ color: "#2B28AE" }} /> View Reviews
                            </p> */}
                        </div>

                    </div>
                </div>
                <div className={`${styles.containers} ${styles.statsContainer}`}>
                    {/* <h3 className={styles.header}>Reviews</h3> */}
                    <Row className={styles.stats} justify="space-around">
                        <Col xl={9} lg={10} md={20} xs={24}>
                            <div className={styles.reviewStatsContainer}>
                                <div className={styles.statsHeaderContainer}>
                                    <h2 className={styles.statsTitle}>Reservation - Weekly View</h2>
                                    <span><DatePicker size={'middle'} picker="week" value={endDate} onChange={(e) => e ? setEndDate(e) : ''} /></span>
                                </div>
                                <p className={styles.statsDescription}>
                                    Week Record From <span>{`${endDate.startOf('week').format('DD MMM')} - ${endDate.endOf('week').format('DD MMM')}`}</span>
                                </p>
                                {(reviewsWeely.isFetched && reviewsWeely.data?.data.length) ? <Bar
                                    data={{
                                        labels: reviewsWeely.data?.data.map(item => item.day),
                                        datasets: [
                                            {
                                                label: 'Reservations',
                                                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                                borderColor: 'rgb(255, 99, 132)',
                                                borderWidth: 1,
                                                data: reviewsWeely.data?.data.map(item => item.total)
                                            }
                                        ]
                                    }}
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            title: {
                                                display: false,
                                            }
                                        }
                                    }}
                                /> : (reviewsWeely.isLoading || reviewsWeely.isFetching) ? <div className={'loadingContainer'} style={{ height: 'inherit', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0 }}>
                                    <Spin indicator={antIcon} style={{ fontSize: "24px" }} />
                                </div> : ''}
                            </div>
                        </Col>

                        <Col xl={9} lg={10} md={20} xs={24}>
                            <div className={styles.reviewStatsContainer}>
                                <div className={styles.statsHeaderContainer}>
                                    <h2 className={styles.statsTitle}>Check In - Weekly View</h2>
                                    <span><DatePicker size={'middle'} picker="week" value={checkInEndDate} onChange={(e) => e ? setCheckInEndDate(e) : ''} /></span>
                                </div>
                                <p className={styles.statsDescription}>
                                    Week Record From <span>{`${checkInEndDate.startOf('week').format('DD MMM')} - ${checkInEndDate.endOf('week').format('DD MMM')}`}</span>
                                </p>
                                {(checkInWeekly.isFetched && checkInWeekly.data?.data.length) ? <Bar
                                    data={{
                                        labels: checkInWeekly.data?.data.map(item => item.day),
                                        datasets: [
                                            {
                                                label: 'Check Ins',
                                                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                                borderColor: 'rgb(255, 99, 132)',
                                                borderWidth: 1,
                                                data: checkInWeekly.data?.data.map(item => item.total)
                                            }
                                        ]
                                    }}
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            title: {
                                                display: false,
                                            }
                                        }
                                    }}
                                /> : (checkInWeekly.isLoading || checkInWeekly.isFetching) ? <div className={'loadingContainer'} style={{ height: 'inherit', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0 }}>
                                    <Spin indicator={antIcon} style={{ fontSize: "24px" }} />
                                </div> : ''}
                            </div>
                        </Col>


                    </Row>

                </div>
            </div>
        </ContentContainer>
    )
}

export default React.memo(SpotDetails)