import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";


export default function PublicRoute({ children }) {

    const { isLoggedIn } = useSelector(state => state.default);


    return (
        isLoggedIn ? <Navigate to={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`} replace /> : children

    );
}
