import React, { useCallback, useEffect, useState } from "react";
import ReactDom from "react-dom";
import styles from "./map.module.scss";

import { useJsApiLoader } from "@react-google-maps/api";
import { Toast } from "../../hooks/useToast"
import Map from "./Map.js"
import _ from "lodash";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function MapContainer({ location, setLatLng, setClose, isEdit = false }) {
    const [permission, setPermission] = useState("denied");
    const [position, setPosition] = useState(location)
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    })
    useEffect(() => {
        if (!location) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    setPosition({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                },
                function (error) {
                    Toast("Site Not Able To access Your location !", "info", false);
                }
            );
        }
    }, [permission])

    useEffect(() => {
        if (_.isEmpty(position)) {
            setLatLng(null);
        } else {
            setLatLng({
                lat: position.lat,
                lng: position.lng,
            });
        }
    }, [position])

    useEffect(() => {
        if (!location) {
            navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
                permissionStatus.onchange = () => {
                    setPermission(permissionStatus.state)
                };
            });
        }

    }, [])

    const handleSetPosition = useCallback((obj) => {
        if (isEdit) {
            setPosition(obj)
        }
    }, [])


    return (
        ReactDom.createPortal(<div className={styles.mapContainer}>
            <div className={styles.background} onClick={() => setClose(false)}>
            </div>
            <div className={styles.map}>
                {!isLoaded ?
                    <Spin indicator={antIcon} /> :
                    <Map position={position} setPosition={handleSetPosition} />
                }
            </div>
        </div>, document.getElementById("modal"))
    )
}

export default React.memo(MapContainer)