import React from "react"
import ReactDom from "react-dom";

import styles from "./index.module.scss"


export default function ModalContainer({ handleClose = () => { }, children }) {
    return ReactDom.createPortal(
        <div className={styles.modalContainer} >
            <div className={styles.outside} onClick={handleClose}></div>
            {children}
        </div>,
        document.getElementById('portal'))
}