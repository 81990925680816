import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom"

/**Import Assets */
import styles from "./ProtectedLayout.module.scss";

/**Import Custom Component */
import Navbar from "./Navbar";
import link1 from "./images/dashboard.svg";
import profile from "./images/profile.svg";
import reservation from "./images/reservation.svg";
import logout from "./images/profile.svg";
import { updateKey } from "../../features/DefaultReducer";
import { resetState as profileReset } from "../../features/ProfileReducer";
// import { resetState as textReset } from "../../features/StaticTextReducer";
// import { resetState as userReset } from "../../features/UserManagementReducer";
// import { resetState as dashboardReset } from "../../features/DashboardReducer";
import { resetValue as searchReset } from "../../features/SearchReducer";

import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from "react-redux";
import { Menu } from 'antd';
import { BiLogOut } from "react-icons/bi"


export default function ProtectedLayout({ selectedRoute, isSearchBar, children }) {

    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false)

    const items = [
        {
            icon: link1,
            label: "Dashboard",
        },
        {
            icon: link1,
            label: "Venue",
        },
        {
            icon: profile,
            label: "Profile",
        },
        {
            icon: reservation,
            label: "Reservation",
        },
        {
            icon: link1,
            label: "Change Password",
        },
        {
            icon: link1,
            label: "Chat",
        },

    ]

    function handleLogout() {
        dispatch(updateKey({ isLoggedIn: false }));
        dispatch(profileReset());
        dispatch(searchReset())
        localStorage.removeItem(process.env.REACT_APP_WEB_STORAGE_TOKEN)

    }

    function submit(title, message, cb, rejectCB = () => { }) {
        confirmAlert({
            title,
            message,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => cb()
                },
                {
                    label: 'No',
                    onClick: () => rejectCB()
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
        });
    }


    const handleToggle = () => {
        setCollapsed(!collapsed)
    }

    const handleMenuClose = () => {
        setCollapsed(false)
    }
    return (
        <div className={styles.protectedLayout}>
            <div className={collapsed ? styles.background : ''} onClick={handleMenuClose}></div>
            <div
                className={`${styles.menu} ${collapsed ? `${styles.collapsed} visible` : styles.notCollapsed}`}
                id="sider"
            >

                <Link className={`${styles.logo} ${!collapsed ? styles.notCollapsed : ''}`} to={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`}>
                    <img src={collapsed ? '/logo.png' : '/logo_small.png'} alt="Logo Image" className={collapsed ? styles.imageCollapsed : styles.imageNotCollapsed} />
                </Link>

                <div className={`${styles.drawer} ${collapsed ? styles.collapsed : ''}`}>

                    <span onClick={handleToggle}>
                        <BiLogOut />
                    </span>
                </div>

                {items.map(({ icon, label }, index) => {
                    return <Link to={`${process.env.REACT_APP_DOMAIN_DIR}/${label.toLowerCase().replace(/[^A-Z0-9]/ig, "-")}`} className={`${styles.item} ${index == selectedRoute ? styles.active : ""}`} key={index}>
                        <img src={icon} alt={`Icon${index}`} width={20} height={20} />
                        {collapsed ? <p>{label}</p> : ''}
                    </Link>
                })}

                <Link className={styles.item} onClick={() => submit("Confirm Logout", "Are you sure you want to logout?", () => handleLogout())} >
                    <img src={logout} alt="Icon1" width={20} height={20} />
                    {collapsed ? <p>Logout</p> : ''}
                </Link>

            </div>
            <div className={styles.routeContent}>
                <Navbar isSearchBar={false} title={items[selectedRoute].label} handleToggle={handleToggle} />
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </div >
    )
}