import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Lookup } from '../Api/Lookup';
import { Toast } from '../hooks/useToast';

export const getLookupsThunk = createAsyncThunk(
    'lookups/get',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await Lookup.get(api_token);
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)



const initialState = {
    status: "loading",
    data: {},
    links: {}
}

export const LookupSlice = createSlice({
    name: 'lookups',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },
        updateKeys: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        resetState: () => {
            return initialState
        }
    },
    extraReducers: {
        [getLookupsThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [getLookupsThunk.pending]: (state, action) => {
            state.status = "pending"
        },
        [getLookupsThunk.rejected]: (state, action) => {
            state.status = "rejected"
        },


    }

})


// Action creators are generated for each case reducer function
export const { setStatus, resetState, updateKeys } = LookupSlice.actions
export default LookupSlice.reducer