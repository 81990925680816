
import { confirmAlert } from 'react-confirm-alert';
import moment from "moment"


export function submit(title, message, cb, rejectCB = () => { }) {
    confirmAlert({
        title,
        message,
        buttons: [
            {
                label: 'Yes',
                onClick: () => cb()
            },
            {
                label: 'No',
                onClick: () => rejectCB()
            }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
    });
}

export function useReservationHeader({ status, setDetails, setMainPage }) {
    return [
        {
            title: 'User Name',
            render: (record, val) => {
                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <img src={val.user_image} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                    <p style={{ color: "black", margin: "0 15px", display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.user_name}</p>

                </div>
            }
        },
        {
            title: 'Spot Name',
            render: (record, val) => {
                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                    <img src={val.spot_image} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                    <p style={{ color: "black", margin: "0 15px", display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.spot_name}</p>

                </div >
            }

        },
        {
            title: 'Date',
            align: "center",
            render: (record, val) => {
                return <p style={{ color: "black", margin: "0", whiteSpace: 'nowrap', textAlign: 'center' }}>{moment(val.date).format('MMM DD, YYYY')}</p>
            }
        },
        {
            title: 'Time',
            align: "center",
            render: (record, val) => {
                return <p style={{ color: "black", margin: "0", whiteSpace: 'nowrap', textAlign: 'center' }}>{moment(val.time, 'hh:mm:ss').format('hh:mm A')}</p>
            }
        },
        {
            render: (val) => {
                return <a style={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center' }} onClick={() => {
                    setDetails(val);
                    setMainPage(status)
                }}>View Details</a>
            }
        },
    ];

}



export const spotInfoHeader = [
    {
        title: 'Spot Name',
        render: (record, val) => {
            return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                <img src={val.banner_image} alt="User Image" style={{ width: "30px", height: "30px", borderRadius: "100%" }} />
                <p style={{ color: "black", margin: "0 15px", display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>{val.name}</p>

            </div >
        }

    },
    {
        title: 'Total Check Ins',
        align: "center",
        render: (record, val) => {
            return <p style={{ color: "black", margin: "0", whiteSpace: 'nowrap', textAlign: 'center' }}>{val.total_checkins}</p>
        }
    },
    {
        title: 'Total Reservations',
        align: "center",
        render: (record, val) => {
            return <p style={{ color: "black", margin: "0", whiteSpace: 'nowrap', textAlign: 'center' }}>{val.total_reservations}</p>
        }
    },

];



