import React, { useState, useEffect, useMemo } from "react";

import styles from "./styles/Details.module.scss"

import ContentContainer from "../../components/ContentContainer";
import { Col, Row } from "antd";
import { BsFillTelephoneFill } from "react-icons/bs"
import { MdEmail } from "react-icons/md"
import moment from "moment";

const Details = ({ details, status, handleBackButton }) => {

    const [RESERVATION_STATUS, SET_RESERVATION_STATUS] = useState(status.toLowerCase().replace(' ', '-'));
    const STATUS = useMemo(() => {
        return (RESERVATION_STATUS == 'pending') ? 'Pending' : (RESERVATION_STATUS == 'in-process') ? 'In Process' : (RESERVATION_STATUS == 'completed') ? 'Completed' : 'Cancelled'
    }, [RESERVATION_STATUS])

    const [timer, setTimer] = useState(() => {
        let duration;
        let formatted;
        if (RESERVATION_STATUS == 'pending') {
            const reservation_time = moment(moment(details.date).format('DD/MM/YYYY') + ' ' + details.time, 'DD/MM/YYYY HH:mm:ss').format();
            duration = moment.duration(moment(reservation_time).diff(moment())).as('milliseconds')
            if (duration < 0) {
                SET_RESERVATION_STATUS('in-process')
                duration = moment.duration(moment(details.endedAt).diff(moment())).as('milliseconds')
            }
            formatted = moment.utc(duration).format('D HH:mm:ss')
            return formatted;
        }
        if (RESERVATION_STATUS == 'in-process') {
            duration = moment.duration(moment(details.endedAt).diff(moment())).as('milliseconds')
            formatted = moment.utc(duration).format('HH:mm:ss')
            if (duration < 0) {
                SET_RESERVATION_STATUS('completed')
            }
            return formatted;
        }
        return null
    });

    const optionsAfter = <div className={styles.statusContainer}>
        <button className={`${styles.status} ${styles[RESERVATION_STATUS]}`}>{STATUS}</button>
    </div>

    useEffect(() => {
        const interval = setInterval(
            () => setTimer(getRemainingTIme()),
            1000,
        );

        return () => clearInterval(interval);
    }, []);

    function getRemainingTIme() {
        let duration;
        let formatted;
        if (RESERVATION_STATUS == 'pending') {
            const reservation_time = moment(moment(details.date).format('DD/MM/YYYY') + ' ' + details.time, 'DD/MM/YYYY HH:mm:ss').format();
            duration = moment.duration(moment(reservation_time).diff(moment())).as('milliseconds')
            if (duration < 0) {
                SET_RESERVATION_STATUS('in-process')
                duration = moment.duration(moment(details.endedAt).diff(moment())).as('milliseconds')
            }
            formatted = moment.utc(duration).format('D HH:mm:ss')
            return formatted;
        }
        if (RESERVATION_STATUS == 'in-process') {
            duration = moment.duration(moment(details.endedAt).diff(moment())).as('milliseconds')
            formatted = moment.utc(duration).format('HH:mm:ss')
            if (duration < 0) {
                SET_RESERVATION_STATUS('completed')
            }
            return formatted;
        }
        return null
    }

    return (
        <ContentContainer hasOptions={true} hasBackButton={true} handleBackButtonClick={handleBackButton} hasOptionsAfter={true} optionsAfter={optionsAfter}>
            <div className={styles.details}>
                <Row gutter={[44, 44]}>

                    <Col xxl={8} xl={13} lg={16}>
                        <div className={styles.cardContainer}>
                            <p className={styles.cardTitle}>Note</p>
                            <div className={styles.card}>
                                <p className={styles.description}>{details.message}</p>
                            </div>

                        </div>
                    </Col>
                    <Col xxl={8} lg={12} md={16}>
                        <div className={styles.cardContainer}>
                            <p className={styles.cardTitle}>User Details</p>
                            <div className={`${styles.card}`}>
                                <Row>
                                    <Col xl={8} lg={10} sm={8} xs={24} >
                                        <img src={details.user_image} alt="User Image" className={styles.userImage} />
                                    </Col>
                                    <Col sm={14} xs={24} >
                                        <div className={styles.userDetailsContainer}>
                                            <h3 className={styles.name}>{details.user_name}</h3>
                                            {details.user_mobileNo ? <p className={styles.info}><span><BsFillTelephoneFill /></span>{details.user_mobileNo}</p> : ''}
                                            {details.user_email ? <p className={styles.info}><span><MdEmail /></span>{details.user_email}</p> : ''}

                                        </div>
                                    </Col>
                                </Row>

                            </div>

                        </div>
                    </Col>
                    {((RESERVATION_STATUS == 'in-process' || RESERVATION_STATUS == 'pending') && timer) ? <Col xxl={8} lg={12} md={16}>
                        <div className={styles.cardContainer}>
                            <p className={styles.cardTitle}>Time Remaining</p>
                            <div className={`${styles.card} ${styles.timeContainer}`}>
                                <h1 className={styles.time}>{timer?.split(' ').length == 2 ? parseInt(timer?.split(' ')[0]) - 1 ? `${timer?.split(' ')[0] - 1}D ${timer?.split(' ')[1]} ` : timer?.split(' ')[1] : timer}</h1>

                            </div>

                        </div>
                    </Col> :
                        RESERVATION_STATUS == 'cancelled' ? <Col xxl={8} xl={12} lg={16}>
                            <div className={styles.cardContainer}>
                                <div className={`${styles.card} ${styles.reasonContainer}`}>
                                    <p className={styles.cardTitle}>Reason</p>
                                    <p className={styles.description}>{details.reason}</p>

                                </div>

                            </div>
                        </Col> : ''}
                    <Col xxl={16} xs={24}>
                        <div className={styles.cardContainer}>
                            <p className={styles.cardTitle}>Booking Schedule</p>
                            <div className={styles.card}>
                                <Row gutter={[44, 0]}>
                                    <Col md={12}>
                                        <p className={styles.info}><span>Spot Name : </span>{details.spot_name}</p>
                                        <p className={styles.info}><span>Address : </span>{details.spot_address}</p>
                                    </Col>
                                    <Col md={12}>
                                        <p className={styles.info}><span>Date : </span>{moment(details.date).format('MMM DD, YYYY')}</p>
                                        <p className={styles.info}><span>Time : </span>{moment(details.time, 'hh:mm:ss').format('hh:mm A')}</p>
                                    </Col>
                                </Row>
                            </div>

                        </div>
                    </Col>
                </Row>
            </div>
        </ContentContainer>
    )
}

export default React.memo(Details);