import React, { useCallback, useState } from "react";

import styles from './styles/Profile.module.scss';

import { Toast } from "../../hooks/useToast";
import MapFixed from "../../components/Map/MapFixed";


import ContentContainer from "../../components/ContentContainer";
import { Input, Upload } from "antd";
import { AiOutlineUser, AiOutlineMail, AiFillHome, AiOutlinePlus } from "react-icons/ai"
import { MdCall } from "react-icons/md"
import _ from "lodash"
import MapContainer from "../../components/Map/MapContainer";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileThunk } from "../../features/ProfileReducer";

const Profile = () => {
    const dispatch = useDispatch();
    const { data: user_details } = useSelector(state => state.profile);
    const [latlng, setLatLng] = useState((!user_details.latitude && !user_details.longitude) ? null : {
        lat: user_details.latitude,
        lng: user_details.longitude
    })
    const [isMap, setIsMap] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [data, setData] = useState(user_details);
    const handleMapClose = useCallback(() => {
        setIsMap(false);
    }, [])
    const handleMapOpen = useCallback(() => {
        setIsMap(true);
    }, [])

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }, [])

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJpgOrPng) {
            Toast('You can only upload JPG/PNG file!', 'error', false);
        }
        else if (!isLt2M) {
            Toast('Image must smaller than 2MB!', 'error', false);
        }
        return isJpgOrPng && isLt2M;
    };

    const handleFileChange = (info) => {
        setData(prev => {
            return {
                ...prev,
                image_url: info.file.originFileObj
            }
        });

    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const handleSubmit = () => {

        if (isSubmitted) return;
        setIsSubmitted(true);
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('mobile_no', data.mobile_no);
        formData.append('address', data.address);
        if (latlng) {
            formData.append('latitude', latlng.lat);
            formData.append('longitude', latlng.lng);
        }
        formData.append('image_url', data.image_url);
        dispatch(updateProfileThunk({ data: formData, setIsSubmitted }))
    }

    return (
        <ContentContainer>
            {isMap ? <MapContainer location={latlng} setLatLng={setLatLng} setClose={handleMapClose} isEdit={true} /> : ""}
            <div className={styles.profile}>
                <div className={styles.fieldsContainer}>
                    <Upload
                        name="banner_image"
                        listType="picture-card"
                        className={`avatar-uploader ${styles.imageContainer}`}
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        accept="image/jpeg,image/png,image/png"
                        value={!_.isObject(data.image_url) ? data.image_url : URL.createObjectURL(data.image_url)}
                        onChange={handleFileChange}
                        customRequest={dummyRequest}
                    >
                        {!_.isEmpty(data.image_url) ?
                            <img
                                src={!_.isObject(data.image_url) ? data.image_url : URL.createObjectURL(data.image_url)}
                                alt="avatar"
                                style={{ width: '100%' }} /> :
                            <>
                                <span className={styles.uploadIcon}><AiOutlinePlus /></span>
                                <h3>Add Image</h3>
                            </>
                        }
                    </Upload>
                    <Input
                        placeholder="Enter name"
                        className={styles.field}
                        prefix={<AiOutlineUser className={styles.icon} />}
                        name="name"
                        value={data.name}
                        onChange={handleInputChange}
                    />
                    <Input
                        placeholder="Enter email"
                        className={styles.field}
                        prefix={<AiOutlineMail className={styles.icon} />}
                        name="email"
                        value={data.email}
                        disabled={true}
                    />
                    <Input
                        placeholder="Enter mobile no"
                        className={styles.field}
                        prefix={<MdCall className={styles.icon} />}
                        name="mobile_no"
                        value={data.mobile_no}
                        onChange={handleInputChange}
                    />
                    <Input
                        placeholder="Enter address"
                        className={styles.field}
                        prefix={<AiFillHome className={styles.icon} />}
                        name="address"
                        value={data.address}
                        onChange={handleInputChange}
                    />
                    <div className={styles.mapContainer} onClick={handleMapOpen}>
                        <MapFixed location={latlng} />
                    </div>
                    <button className={styles.submit} onClick={handleSubmit}>
                        Save
                    </button>

                </div>
            </div>
        </ContentContainer>
    )
}

export default React.memo(Profile);