import { configureStore } from '@reduxjs/toolkit';


import defaultReducer from './features/DefaultReducer';
import ProfileReducer from './features/ProfileReducer';
import SearchReducer from './features/SearchReducer';
import LookupReducer from './features/LookupReducer';
import ChatReducer from './features/ChatReducer';

export const store = configureStore({
  reducer: {
    default: defaultReducer,
    profile: ProfileReducer,
    search: SearchReducer,
    lookup: LookupReducer,
    chat : ChatReducer
  },
})