import React, { useCallback, useEffect, useMemo, useState } from "react";
import RecordContainer from "./RecordContainer";
import Details from "./Details";
import moment from "moment"
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Reservation as ReservationAPI } from "../../Api/Reservation";

const Reservation = () => {
    const { api_token } = useSelector(state => state.profile.data)
    const [status, setStatus] = useState('pending');
    const [page, setPage] = useState("record")
    const [data, setData] = useState(null)


    const { data: reservationData, isError, isFetching, isLoading, isSuccess, refetch } = useQuery({
        queryKey: ['reservations'],
        queryFn: () => ReservationAPI.get(api_token),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
    })
    const getTimeDuration = () => {
        const endTime = moment().endOf('minute').add(1, 'second')
        return moment(endTime).diff(moment(), 'second') * 1000
    }

    useEffect(() => {

        const interval = setInterval(() => {
            refetch()

        }, getTimeDuration());

        return () => clearInterval(interval);

    }, [])

    const handleDetailsBackButton = useCallback(() => {
        setPage('record')
    }, [])


    switch (page) {
        case 'record':
            return <RecordContainer setMainPage={setPage} setDetails={setData} status={status} setStatus={setStatus} data={reservationData?.data[status] ?? []} />
        case 'pending':
            return <Details details={data} handleBackButton={handleDetailsBackButton} status={'Pending'} />
        case 'in-process':
            return <Details details={data} handleBackButton={handleDetailsBackButton} status={'In Process'} />
        case 'completed':
            return <Details details={data} handleBackButton={handleDetailsBackButton} status={'Completed'} />
        case 'cancelled':
            return <Details details={data} handleBackButton={handleDetailsBackButton} status={'Cancelled'} />
    }

}

export default React.memo(Reservation)