import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';


import { ToastContainer } from 'react-toastify';

import { Provider } from 'react-redux'
import { store } from './store.js';

import "react-toastify/dist/ReactToastify.css";
import 'react-confirm-alert/src/react-confirm-alert.css';


import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { SocketProvider } from './Context/SocketContext';
// Create a client
const queryClient = new QueryClient()



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <SocketProvider>
        <ToastContainer />
        <App />
      </SocketProvider>
    </Provider>
  </QueryClientProvider>
);

