import { Axios } from "../hooks/useAxiosInstance";

export const Spot = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        create: async (data, api_token) => await createRecord(data, api_token),
        get: async (page, api_token) => await getRecord(page, api_token),
        getReviewsWeekly: async (date, spot_slug, api_token) => await getReviewsWeeklyRecord(date, spot_slug, api_token),
        getCheckInWeekly: async (date, spot_slug, api_token) => await getCheckInWeeklyRecord(date, spot_slug, api_token),
        getReviews: async (slug, page, api_token) => await getReviewsRecord(slug, page, api_token),
        update: async (data, slug, api_token) => await updateRecord(data, slug, api_token),
        delete: async (data, api_token) => await deleteRecord(data, api_token),
    })


    async function createRecord(data, api_token) {
        const url = "vendor/spot";
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'multipart/form-data'
            }
        }

        return await Axios.post(url, data, option);
    }
    async function getRecord(page, api_token) {
        const url = `vendor/spot?page=${page}`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.get(url, option);
    }

    async function getReviewsRecord(slug, page, api_token) {
        const url = `vendor/spot/${slug}/review?page=${page}`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.get(url, option);
    }

    async function getReviewsWeeklyRecord(date, spot_slug, api_token) {
        const url = `vendor/spot/${spot_slug}/reservation/weekly?date=${date}`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.get(url, option);

    }
    async function getCheckInWeeklyRecord(date, spot_slug, api_token) {
        const url = `vendor/spot/${spot_slug}/check-in/weekly?date=${date}`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.get(url, option);

    }

    async function updateRecord(data, slug, api_token) {
        const url = "vendor/spot/" + slug;
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'multipart/form-data'
            }
        }

        return await Axios.patch(url, data, option);
    }
    async function deleteRecord(slug, api_token) {
        const url = "vendor/spot/" + slug;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.delete(url, option);
    }

    return apis();

})()
