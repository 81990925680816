import React from "react";

import styles from "./index.module.scss"

import { IoMdArrowBack } from "react-icons/io"

const ContentContainer = ({
    hasOptions = false,
    hasBackButton = false,
    hasAddButton = false,
    hasDeleteButton = false,
    hasEditButton = false,
    handleBackButtonClick,
    handleAddButtonClick,
    handleDeleteButtonClick,
    handleEditButtonClick,
    hasOptionsAfter = false,
    optionsAfter = '',
    children
}) => {
    return (
        <div className={styles.container}>
            {hasOptions ? <div className={styles.optionsContainer}>
                {hasBackButton ? <div className={styles.backContainer} onClick={handleBackButtonClick}>
                    <span className={styles.icon}><IoMdArrowBack /></span>
                    <h3 className={styles.backText} >Back</h3>
                </div> : ""}
                <div className={styles.allButtonContainer}>

                    {hasAddButton ?
                        <div className={styles.addButtonContainer}>
                            <button className={`${styles.button} ${styles.addNew}`} onClick={handleAddButtonClick}>
                                <span>+</span>Add New
                            </button>
                        </div> : ""}
                    {hasDeleteButton ?
                        <div className={styles.addButtonContainer}>
                            <button className={`${styles.button} ${styles.delete}`} onClick={handleDeleteButtonClick}>
                                Delete
                            </button>
                        </div> : ""}
                    {hasEditButton ?
                        <div className={styles.addButtonContainer}>
                            <button className={`${styles.button} ${styles.edit}`} onClick={handleEditButtonClick}>
                                Edit
                            </button>
                        </div> : ""}
                    {
                        hasOptionsAfter ? optionsAfter : ""
                    }
                </div>

            </div> : ""}


            <div className={styles.contentContainer}>
                {children}
            </div>
        </div>
    )
}

export default React.memo(ContentContainer)