import React, { useCallback, useState } from "react";
import SpotCards from "./SpotCards";
import SpotDetails from "./SpotDetails";
import { useDispatch } from "react-redux";
import { getLookupsThunk } from "../../features/LookupReducer";


const Spot = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState('card');
    const [data, setData] = useState({});

    React.useEffect(() => {
        dispatch(getLookupsThunk());
    }, [])

    const handleBackButtonClick = useCallback(() => {
        setPage('card')
    }, [])

    const handleCardClick = useCallback((data) => {
        setData(data)
        setPage('card-details')
    }, [])
    return (
        (page == 'card') ? <SpotCards cardClick={handleCardClick} /> :
            <SpotDetails backClick={handleBackButtonClick} data={data} setData={setData} />
    )
}


export default React.memo(Spot)