import React, { useCallback, useState } from "react";

/**Import Styles and Assets */
import styles from "./ContentLayout.module.scss";
import PasswordPopUp from "../PasswordPopUp";
import { useSelector } from "react-redux";


export default function ContentLayout({ children }) {
    const { is_newUser } = useSelector(state => state.profile.data)
    const [isPopUp, setIsPopUp] = useState(is_newUser);


    const handlePopUp = useCallback(() => {
        setIsPopUp(false)
    }, [])

    return (
        <div className={styles.contentLayout}>
            {isPopUp ? <PasswordPopUp handleClose={handlePopUp} /> : ""}
            {children}
        </div>
    )
}