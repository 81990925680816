import React, { useEffect, useMemo, useRef, useState } from "react";

import styles from "./styles/Chat.module.scss"
import { addThread, dataFetched, incrementMessageCount, removeThread, reorderChat, resetState, setStatus } from "../../features/ChatReducer";
import { useSocket } from "../../Context/SocketContext";
import ChatContainer from "./ChatContainer";


import { useDispatch, useSelector } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';
import { Dropdown, Spin } from 'antd';
import { resetValue } from "../../features/SearchReducer";
import { updateKey } from "../../features/DefaultReducer";
import _ from "lodash";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


export default function Chat() {

    const childRef = useRef();
    const { data, status } = useSelector(state => state.chat)
    const { api_token } = useSelector(state => state.profile.data)
    const { search } = useSelector(state => state.search)
    const { isSocketConnected } = useSelector(state => state.default)

    const [chatRoom, setChatRoom] = useState(null)
    const filterThread = useMemo(() => {
        if (!search) return data ?? []
        return data.filter(item => item.room_name.includes(search))
    }, [search, data]);
    const socket = useSocket();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isSocketConnected) {
            socket.emit('_getChatThreads')
        } else {
            socket.connectToSocket(api_token, () => {
                dispatch(updateKey({ isSocketConnected: true }))
            });
        }

    }, [isSocketConnected])


    useEffect(() => {
        socket.on('getChatThreads_', (data) => {
            dispatch(dataFetched(data))
        })

        socket.on('newThread_', (data) => {
            dispatch(addThread(data?.data ?? []))
            socket.emit('_joinRoom', data?.data.chat_room_slug)
        })

        return () => {
            socket.dispose('getChatThreads_')
            socket.dispose('newThread_')
            dispatch(resetValue());
            dispatch(resetState())
        }

    }, [])

    useEffect(() => {
        socket.on("receivedMessage_", ({ data }) => {
            if (data.chat_room_slug == chatRoom) {
                childRef.current.messageReceieved(data)
                socket.emit('_resetMessageCount', { chat_room_slug: chatRoom })
            }
            else {
                dispatch(incrementMessageCount(data.chat_room_slug))
            }
            dispatch(reorderChat(data.chat_room_slug))

        })

        return () => {
            socket.dispose('receivedMessage_')
        }

        //dependcy defined to recreate function as it used chatRoom variable that need to be rerendered
    }, [chatRoom])


    useEffect(() => {
        const slider = document.getElementById('thread_container');
        let mouseDown = false;
        let startX, scrollLeft;

        let startDragging = function (e) {
            mouseDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        };
        let stopDragging = function (event) {
            mouseDown = false;
        };

        let mousemove = function (e) {
            // e.preventDefault();
            if (!mouseDown) { return; }
            const x = e.pageX - slider.offsetLeft;
            const scroll = x - startX;
            slider.scrollLeft = scrollLeft - scroll;
        }

        slider.addEventListener('mousemove', mousemove, { passive: true });

        // Add the event listeners
        slider.addEventListener('mousedown', startDragging, { passive: false });
        slider.addEventListener('mouseup', stopDragging, { passive: false });
        slider.addEventListener('mouseleave', stopDragging, { passive: false });

        return () => {
            slider.removeEventListener("mousedown", startDragging, { passive: false }); // Succeeds
            slider.removeEventListener("mouseup", stopDragging, { passive: false }); // Succeeds
            slider.removeEventListener("mouseleave", stopDragging, { passive: false }); // Succeeds
            slider.removeEventListener("mousemove", mousemove, { passive: true }); // Succeeds
        }
    }, [])


    const handleThreadClick = (value) => {
        if (value == chatRoom) return;
        setChatRoom(value)
    }

    const handleBlockRoom = (chat_room_slug) => {
        if (chat_room_slug) {
            dispatch(removeThread(chat_room_slug))
            socket.emit('_blockChatThread', { chat_room_slug })
        }
    }

    return (
        <div className={styles.cardContainer}>
            <div
                id="thread_container"
                className={styles.chatThreadContainer}
            >
                {(status == 'pending') ?
                    <div className={styles.loadingContainer}>
                        <Spin indicator={antIcon} />
                    </div> : filterThread.map((item, index) => {
                        return <Dropdown
                            menu={{
                                items: [{
                                    key: item.chat_room_slug,
                                    label: 'Block User',
                                    onClick: (e) => handleBlockRoom(e.key)
                                }],
                            }}
                            value={item.chat_room_slug}
                            trigger={['contextMenu']}
                            key={index}
                        >
                            <div
                                className={`${styles.thread} ${(item.chat_room_slug === chatRoom) ? styles.active : ''}`}
                                key={`chat_thread_${index}`}
                                onClick={() => handleThreadClick(item.chat_room_slug)}
                            >
                                <img
                                    src={item.image_url}
                                    alt={`Thread_${index}`}
                                    className={styles.threadLogo}
                                />
                                <p
                                    className={styles.user_name}>
                                    {item.room_name}
                                </p>
                                {item.unread_message_count ? <span className={styles.tag}>{item.unread_message_count}</span> : ''}
                            </div>
                        </Dropdown>

                    })}

            </div>
            <div className={styles.contentContainer}>
                {!chatRoom ? <div className={styles.initialContainer}>
                    <h2 className={styles.title}>Chat With Users</h2>
                    <p className={styles.description}>Send and receive message to/from the users</p>
                </div> : <ChatContainer chatRoom={chatRoom} key={chatRoom} ref={childRef} />

                }

            </div>
        </div >
    )
}