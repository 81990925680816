import React, { useCallback, useState } from "react";

import './MapFixed.css';

import { useJsApiLoader } from "@react-google-maps/api";
import Map from "./Map.js"
import _ from "lodash";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function MapFixed({ location }) {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    })
    return (
        <div className='mapFixed'>
            {!isLoaded ?
                <Spin indicator={antIcon} /> :
                <Map position={location} setPosition={() => { }} option={{ gestureHandling: 'none' }} className="googleMap" />
            }
        </div>
    )
}

export default React.memo(MapFixed)