import React, { useCallback, useState } from "react";

import Cards from "./Cards";
import SpotListing from "./SpotListing";

const Dashboard = () => {
    const [page, setPage] = useState('card');


    const handleSpotClick = useCallback(() => {
        setPage('listing')
    }, [])

    const handleBackClick = useCallback(() => {
        setPage('card')
    }, [])

    switch (page) {
        case 'card':
            return <Cards handleSpotCardClick={handleSpotClick} />;
        case 'listing':
            return <SpotListing handleBackClick={handleBackClick} />;
    }
}

export default React.memo(Dashboard)