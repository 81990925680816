import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";

import styles from "./styles/ChatContainer.module.scss"

import { UploadMedia } from "../../Api/UploadMedia";
import { incrementMessageCount, readAllMessage, reorderChat } from "../../features/ChatReducer";
import { useSocket } from "../../Context/SocketContext";
import { Toast } from "../../hooks/useToast";

import { BsFillSendFill } from "react-icons/bs"
import { GrAttachment } from "react-icons/gr"
import { MdCancel } from "react-icons/md"
import { Input, Upload } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { useInView } from "react-intersection-observer";
import _ from "lodash";


const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ChatContainer = forwardRef(({ chatRoom }, ref) => {

    const MESSAGE_LIMIT = 20;

    const { ref: viewRef, inView } = useInView();
    const dispatch = useDispatch();
    const socket = useSocket()
    const { slug } = useSelector(state => state.profile.data)

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [newMessageCount, setNewMessageCount] = useState(0)
    const [chatPageNo, setChatPageNo] = useState(1);
    const [status, setStatus] = useState('pending')
    const [message, setMessage] = useState('')
    const [file, setFile] = useState(null)
    const [uploadingFile, setUploadingFile] = useState(false);

    useImperativeHandle(ref, () => ({
        messageReceieved(data) {
            setNewMessageCount(prev => prev + 1);
            setData(prev => {
                return [
                    data,
                    ...prev
                ]
            })
        }
    }));

    useEffect(() => {
        setStatus('pending');
        socket.emit('_loadChatHistory', {
            limit: MESSAGE_LIMIT,
            page: chatPageNo,
            offset: ((chatPageNo - 1) * MESSAGE_LIMIT) + newMessageCount,
            chat_room_slug: chatRoom
        });
    }, [chatPageNo])

    useEffect(() => {
        if (inView) {
            setChatPageNo(prev => prev + 1);
        }
    }, [inView]);

    useEffect(() => {

        socket.on("loadChatHistory_", (data) => {
            setData(prev => {
                return [
                    ...prev,
                    ...(data?.data.reverse() ?? [])
                ]
            })
            setPagination(data?.links ?? {})
            setStatus('success')
            dispatch(readAllMessage(chatRoom))
        })

        return () => {
            socket.dispose('loadChatHistory_')
        }

    }, [])

    const handleSubmit = async () => {
        if ((!message.trim() && !file) || uploadingFile) return;

        try {
            setUploadingFile(true);
            let data = {};
            data.chat_room_slug = chatRoom;
            data.message = message;
            data.message_type = 'text'

            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                const result = await UploadMedia.post(formData)
                data.message_type = 'file';
                data.file_url = result?.data.link;
                data.file_name = file?.name
            }

            socket.emit('_sendMessage', data)
            setMessage('');
            setFile(null);
        }
        catch (err) {
            Toast(err.message, 'error', false)
            return;
        }
        finally {
            setUploadingFile(false);
        }
    }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'video/mp4';
        if (!isJpgOrPng) {
            Toast('You can only upload JPG/PNG/JPEG/MP4 file!', 'error', false);
        }

        const MAX_MEDIA = 5;
        const isLt2M = file.size / 1024 / 1024 < MAX_MEDIA;
        if (!isLt2M) {
            Toast(`Media must smaller than ${MAX_MEDIA}MB!`, 'error', false);
        }
        return isJpgOrPng && isLt2M;
    };

    const handleInputChange = (e) => {
        if (uploadingFile) return
        setMessage(e.target.value)
    }
    const handleFileChange = (info) => {
        setFile(info?.file?.originFileObj)
    }


    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    const handleRemoveMedia = () => {
        if (uploadingFile) return;
        confirmAlert({
            title: "Discard Media",
            message: "Your selected media will be removed.",
            buttons: [
                {
                    label: 'Discard',
                    onClick: () => setFile(null)
                },
                {
                    label: 'Return to media',
                    onClick: () => { }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
        });

    }

    const getMediaType = () => {
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') return 'image';
        if (file.type === 'video/mp4') return 'video';
        return;
    }

    return (
        <div className={styles.chatContainer}>
            <div className={styles.chats}>
                {data.map((item, index) => {
                    return (
                        <div
                            className={`${styles.chat} ${(slug == item.user_slug) ? styles.me : styles.other}`}
                            key={`chat_${item.slug}`}
                            ref={(_.isEmpty(pagination) || pagination?.total <= chatPageNo) ? undefined : ((data.length - 1) == index) ? viewRef : undefined}
                        >
                            {item.message_type !== 'file' ? "" :
                                <div className={styles.mediaContainer}>
                                    {/\.(jpg|jpeg|png)$/.test(item.file_url) ?
                                        <img src={item.file_url} alt={`Attachement_${item.slug}`} /> :
                                        <video controls key={item.slug} >
                                            <source src={item.file_url} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>

                                    }
                                </div>}
                            <p className={styles.text}>{item.message}</p>
                        </div>
                    )
                })}
                {(status === 'pending') ?
                    <div className={styles.loadingContainer}>
                        <Spin indicator={antIcon} />
                    </div> : ""}
            </div>

            {!file ? '' : <div className={styles.filebackground} onClick={handleRemoveMedia}></div>}
            <div className={styles.chatInputContainer}>
                {!file ? '' : <div className={styles.fileContainer}>
                    <button className={styles.removeFile} onClick={handleRemoveMedia}>
                        <MdCancel className={styles.cancelIcon} />
                    </button>
                    {getMediaType(file) === 'image' ?
                        <img src={URL.createObjectURL(file)} alt={`File Preview Image`} className={styles.preview} /> :
                        <video key={'File Thumbnail'} className={styles.preview}>
                            <source src={URL.createObjectURL(file)} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>

                    }
                    <p className={styles.filename}>{file?.name}</p>
                    {uploadingFile && <span className={styles.uploading}> <Spin indicator={antIcon} /></span>}

                </div>}
                <div className={styles.fieldContainer}>
                    <Input type="text" placeholder="Type a message" value={message} onChange={handleInputChange} onPressEnter={handleSubmit} />
                    <Upload
                        showUploadList={false}
                        action=""
                        beforeUpload={beforeUpload}
                        onChange={handleFileChange}
                        accept="video/mp4,.png,.jpg,.jpeg"
                        customRequest={dummyRequest}
                    >
                        <button className={styles.attachment} onClick={() => { }}><GrAttachment className={styles.attachment} /></button>
                    </Upload>
                    <button className={styles.send} onClick={handleSubmit}><BsFillSendFill className={styles.icon} /></button>
                </div>


            </div>

        </div >
    )

})

export default ChatContainer
