import React, { useCallback, useState } from "react";

import styles from "./styles/AddOrUpdateSpot.module.scss"

import ModalContainer from "../../components/ModalContainer.js";
import { Toast } from "../../hooks/useToast";
import MapContainer from "../../components/Map/MapContainer";

import { RxCross2 } from "react-icons/rx"
import { Input, Upload } from "antd";
import { Select } from 'antd';
import { FaMapMarkerAlt } from "react-icons/fa"
import { AiOutlinePlus } from "react-icons/ai"
import _ from "lodash"
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import { Spot } from "../../Api/Spot";


const AddOrUpdateSpot = ({ isEdit = false, handleClose, details, setDetails, handleEnableQuery }) => {
    const TITLE = isEdit ? 'Edit Spot Details' : "Add Spot Details"

    const { api_token } = useSelector(state => state.profile.data);
    const { data: lookupsData, status } = useSelector(state => state.lookup);

    const queryClient = useQueryClient();
    const createSpotMutation = useMutation({
        mutationFn: async (data) => await Spot.create(data, api_token),
        onSuccess: () => {
            Toast('Spot Created Successfully', 'success', false);
            handleEnableQuery()
            handleClose();
        },
        onError: (err) => {
            Toast(err.message, 'error', false)
            setIsSubmitted(false);
        }
    })

    const updateSpotMutation = useMutation({
        mutationFn: async (data) => await Spot.update(data, details.slug, api_token),
        onSuccess: (res) => {
            setDetails(res.data)
            Toast('Spot Details Updated Successfully', 'success', false);

            queryClient.setQueryData(["spots"], (data) => {
                return {
                    ...data,
                    pages: data.pages.map(item => {
                        return {
                            ...item,
                            data: item.data.map(item => item.slug === res.data.slug ? res.data : item)
                        }
                    })
                }
            })
            handleClose();
        },
        onError: (err) => {
            Toast(err.message, 'error', false)
            setIsSubmitted(false);
        }
    })

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isMap, setIsMap] = useState(false);

    const [data, setData] = useState(isEdit ? details : {
        banner_image: null,
        name: '',
        address: '',
        tags: [],
        category: undefined,
        price: undefined,
        email: '',
        mobile_no: '',
        image_url: [],
        website_url: '',
        reservation_url: '',

    })

    const [location, setLocation] = useState(!isEdit ? null : {
        lat: details.latitude,
        lng: details.longitude
    })
    const [deletedMedia, setDeletedMedia] = useState([])

    const image_arr = details?.image_url || [];
    const filteredOptions = (status == 'success' ? lookupsData?.cuisine : []).filter((o) => !data.tags.includes(o))


    const handleMapOpen = useCallback(() => {
        setIsMap(true)
    }, [])

    const handleMapClose = useCallback(() => {
        setIsMap(false)
    }, [])
    const handleTagsChange = useCallback((e) => {
        setData(prev => {
            return {
                ...prev,
                tags: e
            }
        })

    }, [])
    const handleCategoryChange = useCallback((e) => {
        setData(prev => {
            return {
                ...prev,
                category: e
            }
        })

    }, [])

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }, [])

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJpgOrPng) {
            Toast('You can only upload JPG/PNG file!', 'error', false);
        }
        else if (!isLt2M) {
            Toast('Image must smaller than 2MB!', 'error', false);
        }
        return isJpgOrPng && isLt2M;
    };


    const beforeMultipleUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJpgOrPng) {
            Toast('You can only upload JPG/PNG file!', 'error', false);
        }
        else if (!isLt2M) {
            Toast('Image must smaller than 2MB!', 'error', false);
        }
        if (!isJpgOrPng || !isLt2M) return false;

        setData(prev => {
            let arr = [];
            arr = prev.image_url.filter(item => !deletedMedia.includes(item))
            arr = [...arr, file]
            return {
                ...prev,
                image_url: arr
            }
        });

    };


    const handleFileChange = (info) => {
        setData(prev => {
            return {
                ...prev,
                banner_image: info.file.originFileObj
            }
        });

    }

    const handleDeleteImage = useCallback((index) => {
        const image = data.image_url[index]
        if (!_.isObject(image)) {
            setDeletedMedia(prev => [...prev, image]);
        }
        setData(prev => {
            return {
                ...prev,
                image_url: prev.image_url.filter((_, ind) => ind != index)
            }
        })

    }, [])

    const handleSubmitForm = () => {
        if (isSubmitted) return;

        if (!data.address || !data.email || !data.mobile_no || !data.name || !data.tags.length || !data.category)
            return Toast('Field should not be empty', 'error', false)
        if ((parseFloat(data.price) || 0) <= 0) {
            return Toast("Spot price should be greater than 0", 'error', false);
        }
        if (!data.banner_image)
            return Toast("Select banner image For your spot", 'error', false);
        if (!location)
            return Toast("Select location of your spot", 'error', false);



        setIsSubmitted(true);
        const formData = new FormData();
        formData.append('address', data.address)
        formData.append('email', data.email)
        formData.append('mobile_no', data.mobile_no)
        formData.append('name', data.name)
        formData.append('category', data.category)
        formData.append('price', data.price)
        formData.append('banner_image', data.banner_image)
        formData.append('website_url', data.website_url)
        formData.append('reservation_url', data.reservation_url)
        formData.append('latitude', location.lat)
        formData.append('longitude', location.lng)

        if (data.tags.length == 1) {
            formData.append('tags[0]', data.tags[0])
        }
        else {
            for (let i = 0; i < data.tags.length; i++) {
                formData.append('tags', data.tags[i])
            }
        }

        for (let i = 0; i < data.image_url.length; i++) {
            formData.append('image_url', data.image_url[i])
        }

        if (!isEdit) {
            Toast('Creating New Spot', 'loading', true);
            createSpotMutation.mutate(formData)
        }
        else {
            for (let i = 0; i < deletedMedia.length; i++) {
                formData.append(`deleted_media[]`, deletedMedia[i])
            }
            Toast('Updataing Spot Details', 'loading', true);
            updateSpotMutation.mutate(formData)
        }

    }

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <ModalContainer handleClose={() => { }}>
            {isMap ? <MapContainer location={location} setLatLng={setLocation} setClose={handleMapClose} isEdit={true} /> : ""}
            <div className={styles.addOrUpdateSpot}>
                <div className={styles.headerContainer}>
                    <h1 className={styles.title}>{TITLE}</h1>
                    <span onClick={() => !isSubmitted ? handleClose() : () => { }}><RxCross2 /></span>
                </div>
                <hr />
                <div className={styles.fieldsContainer}>
                    <Upload
                        name="banner_image"
                        listType="picture-card"
                        className={`avatar-uploader ${styles.container}`}
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        accept="image/jpeg,image/png,image/png"
                        onChange={handleFileChange}
                        customRequest={dummyRequest}
                    >
                        {!_.isEmpty(data.banner_image) ?
                            <img
                                src={!_.isObject(data.banner_image) ? data.banner_image : URL.createObjectURL(data.banner_image)}
                                alt="avatar"
                                style={{ width: '100%', height: '100%' }} /> :
                            <>
                                <span className={styles.uploadIcon}><AiOutlinePlus /></span>
                                <h3>Add Image</h3>
                            </>
                        }
                    </Upload>
                    <Input
                        size="large"
                        placeholder="Enter Spot Name *"
                        className={styles.field}
                        name='name'
                        value={data.name}
                        onChange={handleInputChange}
                    />
                    <Input
                        size="large"
                        placeholder="Enter Spot Address *"
                        className={styles.field}
                        name='address'
                        value={data.address}
                        onChange={handleInputChange}
                    />
                    <p className={styles.map} onClick={handleMapOpen}>
                        <FaMapMarkerAlt style={{ color: "red" }} />{location ? 'View On Map' : 'Select Location'}
                    </p>
                    <Select
                        mode="multiple"
                        placeholder="Select Spot Tags *"
                        value={data.tags}
                        onChange={handleTagsChange}
                        style={{ width: '100%' }}
                        size="large"
                        className={styles.field}
                        loading={status == 'pending'}
                        options={filteredOptions.map((item) => ({
                            value: item,
                            label: item,
                        }))}
                    />
                    <Select
                        mode="single"
                        placeholder="Select Spot Category *"
                        value={data.category}
                        onChange={handleCategoryChange}
                        style={{ width: '100%' }}
                        size="large"
                        className={styles.field}
                        loading={status == 'pending'}
                        options={(lookupsData?.spot_category ?? []).map((item) => ({
                            value: item,
                            label: item,
                        }))}
                    />
                    <Input
                        type="number"
                        size="large"
                        placeholder="Enter Spot Price *"
                        className={styles.field}
                        name='price'
                        value={data.price}
                        onChange={handleInputChange}
                    />
                    <Input
                        size="large"
                        placeholder="Enter Email *"
                        className={styles.field}
                        name='email'
                        value={data.email}
                        onChange={handleInputChange}

                    />
                    <Input
                        size="large"
                        placeholder="Enter Mobile No *"
                        className={styles.field}
                        name='mobile_no'
                        value={data.mobile_no}
                        onChange={handleInputChange}
                    />
                    <Input
                        size="large"
                        placeholder="Enter Website URL"
                        className={styles.field}
                        name='website_url'
                        value={data.website_url}
                        onChange={handleInputChange}
                    />
                    <Input
                        size="large"
                        placeholder="Enter Reservation Page URL"
                        className={styles.field}
                        name='reservation_url'
                        value={data.reservation_url}
                        onChange={handleInputChange}
                    />
                    <div className={styles.field}>
                        <p className={styles.addImageLabel}>Add Spot Images</p>
                        <div className={styles.imagesContainer}>

                            <Upload
                                name="image_url"
                                listType="picture-card"
                                className={`avatar-uploader ${styles.container} ${styles.multi}`}
                                showUploadList={false}
                                beforeUpload={beforeMultipleUpload}
                                accept="image/jpeg,image/png,image/png"
                                customRequest={dummyRequest}
                                multiple={true}
                            >
                                <>
                                    <span className={styles.uploadIcon}><AiOutlinePlus /></span>
                                </>
                            </Upload>
                            {_.isEmpty(data.image_url) ? "" :
                                data.image_url.map((item, index) => {
                                    return (
                                        <div className={styles.image} key={index} value={index} onClick={() => handleDeleteImage(index)}>
                                            <img src={_.isObject(item) ? URL.createObjectURL(item) : item} alt={`Spot Image ${index}`} />
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>

                    <button className={styles.submit} onClick={handleSubmitForm}>
                        Save
                    </button>
                </div>

            </div>
        </ModalContainer>
    )
}


export default React.memo(AddOrUpdateSpot)