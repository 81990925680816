import React, { useCallback, useEffect, useState } from "react";

/**Import Styles And Assets */
import styles from "./styles/SpotCards.module.scss"

/**Import Custom Component */
import ContentContainer from "../../components/ContentContainer";
import AddOrUpdateSpot from "./AddOrUpdateSpot";

/**Import Packaged Component */
import { Col, Row } from "antd";
import { AiTwotoneStar } from "react-icons/ai"
import { TbMapPinSearch } from "react-icons/tb"
import { useInfiniteQuery } from "react-query";
import { Spot } from "../../Api/Spot";
import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import { Toast } from "../../hooks/useToast";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const SpotCards = ({ cardClick }) => {
    const { ref, inView } = useInView();
    const { api_token } = useSelector(state => state.profile.data)
    const [isAddCard, setIsAddCard] = useState(false);

    const [enabled, setEnabled] = useState(true);
    const { data, isFetched, isLoading, isRefetching, hasNextPage, fetchNextPage, isFetchingNextPage } =
        useInfiniteQuery("spots", async ({ pageParam = 1 }) => await Spot.get(pageParam, api_token), {
            enabled: enabled,
            retry: 0,
            onSuccess: () => {
                setEnabled(false)
            },
            onError: (err) => Toast(err.message, 'error', false),
            getNextPageParam: (lastPage, allPages) => {
                return (lastPage.links.current < lastPage.links.total) ? lastPage.links.current + 1 : false
            },
        });

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage]);

    const handleCloseAddCard = useCallback(() => {
        setIsAddCard(false)
    }, [])

    const handleEnableQuery = useCallback(() => {
        setEnabled(true);
    }, [])

    return (
        <ContentContainer hasOptions={true} hasAddButton={true} handleAddButtonClick={() => setIsAddCard(true)} hasBackButton={false} >
            {isAddCard ? <AddOrUpdateSpot handleClose={handleCloseAddCard} handleEnableQuery={handleEnableQuery} /> : ""}
            <Row gutter={[30, 40]}>
                {isFetched && !isLoading && !((data?.pages ?? []).length ? data.pages[0].data.length ? true : false : false) ? <div className='emptyContainer'>
                    <h1 className='title'>No Data Found</h1>
                </div> :
                    (data?.pages ?? []).map((page, i) => {
                        const halfPage = Math.floor(data.pages.length / 2);
                        return (page?.data ?? []).map((item, index) => {
                            return <Col xxl={4} xl={6} md={8} sm={12} xs={20} className={styles.card} key={index} ref={(i == halfPage && index === Math.floor((page?.data.length - 1) / 2) && hasNextPage) ? ref : undefined}>
                                <div className={styles.cardContent}>
                                    <div className={styles.imageContainer}>
                                        <img src={item.banner_image} alt={`Card Image ${index}`} />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <div className={styles.header}>
                                            <h3 className={styles.name}>
                                                {item.name}
                                            </h3>
                                            {/* <p className={styles.rating}>
                                                <span><AiTwotoneStar /></span> {item.total_rating}
                                            </p> */}

                                        </div>
                                        <p className={styles.checkIns}>
                                            <TbMapPinSearch /> {item.total_checkIns ?? 0} Check in
                                        </p>
                                        <button
                                            className={styles.details}
                                            onClick={() => cardClick(data.pages[i].data[index])}
                                        >
                                            View Details
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        })
                    })}
            </Row>
            {(isLoading || isFetchingNextPage || isRefetching) ? <div className={'loadingContainer'}>
                <Spin indicator={antIcon} style={{ fontSize: "24px" }} />
            </div> : ''}

        </ContentContainer >
    )
}


export default React.memo(SpotCards)