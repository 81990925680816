import React, { useContext, useEffect } from "react";
import socket from "../Config/Socket";
import { useDispatch, useSelector } from "react-redux";
import { updateKey } from "../features/DefaultReducer";


const SocketContext = React.createContext();

export function useSocket() {
    return useContext(SocketContext);
}


export function SocketProvider({ children }) {
    const { isLoggedIn } = useSelector(state => state.default)
    const data = useSelector(state => state.profile.data)
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLoggedIn) {
            socket.connectToSocket(data?.api_token, () => {
                dispatch(updateKey({ isSocketConnected: true }))
            });
        }
        else {
            socket.disconnect()
        }
        return () => socket.disconnect();
    }, [isLoggedIn])

    return <SocketContext.Provider value={socket}>
        {children}
    </SocketContext.Provider>
}